/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 64번째 로그에서는 원격 녹음, 우아한형제들 인수, 루비 2.7 릴리스, JapanTaxi 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "발리에서 원격 녹음"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%B0%9C%EB%A6%AC%EC%84%AC"
  }, "발리섬 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sounddevices.com/product/mixpre-3-ii/"
  }, "MixPre-3 II - Sound Devices")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rogueamoeba.com/audiohijack/"
  }, "Rogue Amoeba | Audio Hijack: Record Any Audio on MacOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.com/maps/place/Ubud,+Gianyar,+Bali,+Indonesia/@-8.4960683,115.2485299,14z/data=!3m1!4b1!4m5!3m4!1s0x2dd23d739f22c9c3:0x54a38afd6b773d1c!8m2!3d-8.5068536!4d115.2624778"
  }, "Ubud - Google Maps")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gojek.com/"
  }, "Gojek Super App: Ojek Online, Taksi Online, Pesan Makan, Kirim Barang, Pembayaran")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gojek.com/gofood/"
  }, "GoFood: Aplikasi Pesan Antar Makanan Online 24 Jam | Gojek")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Internet_in_Indonesia"
  }, "Internet in Indonesia - Wikipedia")), "\n"), "\n", React.createElement(_components.h2, null, "우아한 형제들, 딜리버리 히어로에 인수"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.zdnet.co.kr/view/?no=20191230170402"
  }, "공정위, 요기요·배민 기업결합 신고서 접수 - ZDNet korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20160718067500002"
  }, "공정위 'SKT-CJ헬로비전 합병 불허' 최종 결론 | 연합뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/it/article/2019111068317"
  }, "3년 전엔 No라더니…공정위 통신방송 허가 왜 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.netflix.com/kr/title/80184771"
  }, "인사이드 빌 게이츠 | Netflix 공식 사이트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/home/view/2000/06/67966/"
  }, "MS 분할 판결 내용과 향후 일정 - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=187451859"
  }, "알라딘: 배드 블러드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/David_Boies"
  }, "David Boies - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kakao.com/order"
  }, "카카오톡 주문하기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.coupang.com/np/eats-job"
  }, "쿠팡이츠")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23664054"
  }, "배민도 코스닥 대신 해외로…국내 IPO 꺼리는 유니콘 기업 - 중앙일보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20191112004000072"
  }, "우버 공동창업자 캘러닉, 매매제한 풀리자 5억불 상당 지분 매각 | 연합뉴스")), "\n"), "\n", React.createElement(_components.h2, null, "44bits 소식"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pages-origin.hubtee.com/ko/post/44bits-stdoutfm-2019-settlement"
  }, "44bits 프로젝트 2019년 결산: 블로그, 팟캐스트, 유튜브 인기 컨텐츠 등 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/13/"
  }, "stdout_013.log: 도커를 굳이 왜 사용해야하나요? | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/37/"
  }, "stdout_037.log: 캡처보드, Cloudflare 장애, 44bits 테라폼 무크지 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/aws-certification-solutions-architect-associate"
  }, "AWS 솔루션 아키텍트 어소시에이트 자격증 취득 후기 - AWS 공인 자격증 준비와 합격자 혜택 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--github-announcing-unlimited-free-private-repository"
  }, "깃헙(GitHub) 새로운 가격 정책 및 엔터프라이즈 플랜 발표 - 무료 플랜도 비공개 저장소를 무제한 사용 가능 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--ms-build-2019-introducing-windows-terminal"
  }, "마이크로소프트 빌드 2019, 윈도우 터미널 발표 - 파워셸, 커맨드 프롬프트 WSL 지원 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.publickey1.jp/blog/19/1publickey_2019.html"
  }, "(일본어) 1년간 Publickey에서 가장 많이 읽힌 기사, 2019년 통합 랭킹 발표 -  Publickey")), "\n"), "\n", React.createElement(_components.h2, null, "루비 2.7 / Advent Calendar / JapanTaxi 등"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ruby-lang.org/en/news/2019/12/25/ruby-2-7-0-released/"
  }, "Ruby 2.7.0 Released")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hub.docker.com/r/rubylang/ruby"
  }, "rubylang/ruby - Docker Hub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2020"
  }, "RubyKaigi 2020")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qiita.com/advent-calendar/2019/ranking/subscriptions/categories/programming_languages"
  }, "(일본어) Advent Calendar 2019 Ranking - Qiita")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://japantaxi.co.jp/"
  }, "(일본어) JapanTaxi주식회사")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ja.wikipedia.org/wiki/JapanTaxi"
  }, "(일본어) JapanTaxi - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://japantaxi.co.jp/recruit/"
  }, "(일본어) Recruit | JapanTaxi주식회사")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.japantaxi.co.jp/2018/12/17/2662"
  }, "(일본어) 경로탐색에서 자주 발생했던 커넥션 에러와의 만남과 이별 | JapanTaxi블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qiita.com/advent-calendar/2019/japantaxi"
  }, "(일본어) JapanTaxi Advent Calendar 2019 - Qiita")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bloter.net/archives/280174"
  }, "카카오택시, 일본에서도 쓴다 | Bloter.net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://esa.io/"
  }, "esa - A document sharing service for motivated teams")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
